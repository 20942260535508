<template>
  <ToolsFormEdgeContact
    :prop-bearing-width="bearingWidth"
    :prop-bearing-length="bearingLength"
    :prop-bearing-thickness="bearingThickness"
    :prop-twisting-a="twistingA"
    :prop-twisting-b="twistingB"
    :prop-twisting-with-surcharge="twistingWithSurcharge"
    :prop-type-contact-surface="typeContactSurface"
    :disable-inputs="disableInputs"
  />
</template>

<script>
import ToolsFormEdgeContact from '../../components/tools/ToolsFormEdgeContact.vue';

export default {
  components: {
    ToolsFormEdgeContact,
  },
  data() {
    return {
      bearingWidth: this.$store.state.fixedBearingWidth,
      bearingLength: this.$store.state.fixedBearingLength,
      bearingThickness: this.$store.state.fixedBearingThickness,
      twistingA: this.$store.state.fixedBearingTwistingA / 1000,
      twistingB: this.$store.state.fixedBearingTwistingB / 1000,
      twistingWithSurcharge: this.$store.state.fixedBearingTwistingWithSurcharge,
      typeContactSurface: this.$store.state.fixedBearingTypeContactSurface,
      disableInputs: [
        'bearingWidth',
        'bearingLength',
        'bearingThickness',
        'radTwistingA',
        'radTwistingB',
        'withTwistSurcharge',
        'typeContactSurface',
      ],
    };
  },
};
</script>

<style scoped lang="scss">

</style>
